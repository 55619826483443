<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="this.$t('scolarite.matieres')" key="1" />
        <a-tab-pane :tab="this.$t('bulletin.subModule')" key="2" />
        <a-tab-pane
          :tab="this.$t('scolarite.affectationEnseignants')"
          key="3"
        />
        <a-tab-pane :tab="this.$t('scolarite.typePunitions')" key="4" />
      </a-tabs>
    </div>
    <div class="card-body">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :key="loadKey"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import subjects from "./components/subjects";
import teachers from "./components/teachers";
import punishmentType from "./components/punishmentType";
import subModule from "./components/subModule";

export default {
  name: "etablissement",
  components: {
    subjects,
    teachers,
    punishmentType,
    subModule,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "1":
          return "subjects";
        case "2":
          return "subModule";
        case "3":
          return "teachers";
        case "4":
          return "punishmentType";
      }
    },
  },
  methods: {
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>
