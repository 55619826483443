<template>
  <div class="card">
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="
        $t('scolarite.modifierListeEnseignants', {
          name: this.selectedClass.name,
        })
      "
      :footer="false"
      width="50%"
      v-if="selectedClass"
    >
      <a-form :form="form" @submit="affectTeachers">
        <a-transfer
          :titles="[$t('scolarite.nonAffectes'), $t('scolarite.affectes')]"
          :data-source="listAllTeachers"
          show-search
          :list-style="{
            width: '290px',
            height: '290px',
          }"
          :target-keys="targetKeys"
          :filter-option="filterOption"
          @change="handleChangeTransfer"
          :render="renderItem"
        />
        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button key="back" @click="handleCancel" :disabled="loadingClasse">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      rowKey="_id"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
      :customRow="
        (record) => {
          return {
            on: {
              click: () => {
                affClass(record);
              },
            },
          };
        }
      "
    >
      <template slot="name" slot-scope="text, record">
        <div key="name">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, 'name')"
          />
          <template v-else> {{ text }} </template>
        </div>
      </template>
      <template slot="teachers" slot-scope="teachers">
        <ul>
          <template v-for="teacher in teachers" v-if="isNotDeleted(teacher)">
            <li :key="teacher._id" v-html="getTeacherName(teacher)"></li>
          </template>
        </ul>
      </template>
      <span slot="operation">
        <a-button type="primary">
          <a-icon type="edit" />{{ $t("action.modifier") }}
        </a-button>
      </span>
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${$t('personnel.chercher')}  ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >{{ $t("paiement.chercher") }}</a-button
        >
        <a-button
          size="small"
          style="width: 90px"
          @click="() => handleReset(clearFilters)"
          >{{ $t("action.reinitialiser") }}</a-button
        >
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
var classesData = [];
export default {
  name: "classrooms",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Affectation des enseignants",
    });

    apiClient
      .post("/classrooms/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        classesData = [...res.data];
        if (classesData.length == 0)
          this.$message.warning("Aucune classe est trouvé");
        classesData = classesData.map((elem) => {
          return {
            ...elem,
            key: elem._id,
          };
        });
        this.data = classesData;
        this.cacheData = classesData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
      });

    //Get teachers
    apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        this.listAllTeachers = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
        this.tableLeading = false;
      })
      .catch((e) => {
        console.log(e);
        this.$message.error("Aucun Enseignant est trouvé");
      });
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("scolarite.nom"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("scolarite.enseignants"),
          dataIndex: "teachers",
          key: "teachers",
          scopedSlots: {
            customRender: "teachers",
          },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      selectedClass: null,
      listAllTeachers: [],
      targetKeys: [],
    };
  },
  methods: {
    filterOption(inputValue, option) {
      console.log(inputValue, option);
      let name = option.employee.firstName + option.employee.lastName;
      return name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    },
    affectTeachers(e) {
      this.loadingClasse = true;
      e.preventDefault();
      apiClient
        .patch("/classrooms/" + this.selectedClass._id, {
          data: { teachers: this.targetKeys },
        })
        .then((res) => {
          this.$message.success(
            this.$t("success.modificationsEnseignantsEffectue")
          );
          this.selectedClass.teachers = this.targetKeys;
          var foundIndex = this.data.findIndex(
            (x) => x._id == this.selectedClass._id
          );
          this.data[foundIndex] = this.selectedClass;
          console.info(this.targetKeys);
          console.info(this.selectedClass._id);
          apiClient
            .post("/teacherAccess/syncClassroomAffectations", {
              teacherList: this.targetKeys,
              classRoom: this.selectedClass._id,
            })
            .then((res) => this.handleCancel())
            .catch((e) =>
              this.$message.error(this.$t("error.impoModifiEnseignants"))
            );
        })
        .catch((e) =>
          this.$message.error(this.$t("error.impoModifiEnseignants"))
        )
        .finally(() => (this.loadingClasse = false));
    },
    affClass(record) {
      this.selectedClass = record;
      this.targetKeys = this.selectedClass.teachers;
      this.showModal();
    },
    renderItem(item) {
      const teacherActive = (
        <span class="custom-item">
          {item.employee.firstName} {item.employee.lastName}
        </span>
      );
      const teacherDeleted = (
        <span class="ant-tag ant-tag-red">
          {item.employee.firstName} {item.employee.lastName} (supprimé)
        </span>
      );
      const advisor = (
        <span class="ant-tag ant-tag-green">
          {item.employee.firstName} {item.employee.lastName} (advisor)
        </span>
      );
      let label = undefined;
      if (item.status == "active") {
        if (item.role === "advisor") label = advisor;
        else label = teacherActive;
      } else label = teacherDeleted;

      return {
        label: label, // for displayed item
        value: item.title, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      apiClient
        .patch("/subject/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.matiereMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },

    getTeacherName(id) {
      let teacher = this.listAllTeachers.find((el) => el._id == id);
      if (teacher) {
        let name = teacher.employee.firstName + " " + teacher.employee.lastName;
        if (teacher.status === "active") return name;
        else
          return (
            "<span class='ant-tag ant-tag-red'>" +
            name +
            " (" +
            this.$t("scolarite.supprime") +
            ") </span>"
          );
      }
    },
    isNotDeleted(id) {
      let teacher = this.listAllTeachers.find((el) => el._id == id);
      if (teacher) {
        if (teacher.status === "active") return true;
        else return false;
      }
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
>
